import {createRouter, createWebHistory} from "vue-router";

import Home from "@/components/frame/HomeFrame.vue";
import Login from "@/components/login/UserLogin.vue";

let routes = [
    {
        path: "/",
        name: "home",
        component: Home
    }, {
        path: "/login",
        name: "login",
        component: Login
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;