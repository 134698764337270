import request from "@/components/util/http";

export default {
    methods:{
        apiIndustryForArea(areaCode){
            return request({
                url: '/api/product/oss/product-industry/select-enabled/'+areaCode,
                method: 'get'
            })
        }
    }
}