export default {
  namespaced: true,
  state: {
    showAjaxLoading: false
  },
  mutations:{
    saveShowAjaxLoading(state,value){
      state.token = value
    }
  },
  actions:{
    setShowAjaxLoading({ commit },payload){
      commit('saveShowAjaxLoading',payload)
    }
  }
}
