import request from "@/components/util/http";
import Storage from "@/components/util/Storage";

export default {
    mixins:[ Storage ],
    methods:{
        apiSendRefundMsg(orderNo,refundAmount){
            return request({
                url : '/api/shopping/oss/wx-pay/'+orderNo+'/refund/validate-code',
                method: 'post',
                params:{
                    refundAmount: refundAmount
                }
            })
        },
        apiRefund(orderNo,refundAmount,validateCode,comment){
            return request({
                url: '/api/shopping/oss/wx-pay/'+orderNo+'/refund',
                method: 'post',
                data:{
                    refundAmount: refundAmount,
                    validateCode: validateCode,
                    comment: comment
                }
            })
        }
    }
}