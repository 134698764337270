import request from "@/components/util/http";


export default {
    methods:{
        apiAreas(){
            return request({
                url: '/api/product/oss/area/enabled/select',
                method: 'get'
            })
        },
        apiAreaForCode(areaCode){
            return request({
                url: '/api/product/oss/area/'+areaCode,
                method: 'get'
            })
        }
    }
}