import request from "@/components/util/http";
import Storage from "@/components/util/Storage";

export default {
    mixins:[ Storage ],
    methods:{
        apiAddVipDetail(userId,orderNo,productNo,authdays){
            return request({
                url : '/api/exam/oss/vip-detail',
                method: 'post',
                data:{
                    userId: userId,
                    orderNo: orderNo,
                    productNo: productNo,
                    authDays: authdays,
                }
            })
        },
        apiCloseVipDetail(orderNo){
            return request({
                url : '/api/exam/oss/vip-detail/'+orderNo,
                method: 'delete'
            })
        }
    }
}