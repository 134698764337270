<template>
<div class="list-tabs">
  <div class="list-tab" :class="{'current-tab':currentIndex===config.id}" v-for="config in configs" @click="changeTab(config.id)" :key="config.id">
    {{config.tabName}}
  </div>
</div>
</template>

<script>
export default {
  name: "ListHeaderTabs",
  emits:["tab-change"],
  props:{
    configs: Array,
    defaultTabId: Number
  },
  data(){
    return {
      currentIndex: 0
    }
  },
  methods:{
    changeTab(id){
      if(this.$data.currentIndex === id){
        return
      }
      this.$data.currentIndex = id
      this.$emit("tab-change",id)
    },
    getFilters(){
      return this.$data.currentIndex
    }
  },
  created() {
    this.$data.currentIndex = this.$props.defaultTabId
  }
}
</script>

<style scoped>
  .list-tabs{
    background-color: #F7F7F7;
    overflow: hidden;
    position: relative;
  }
  .list-tabs .list-tab{
    font-size: var(--el-font-size-base);
    display: inline-block;
    padding: 8px 20px;
    margin-left: -1px;
    cursor: pointer;
    border: 1px solid transparent;
  }
  .current-tab{
    background-color: #ffffff;
    border-left-color: #e7ecf1 !important;
    border-right-color: #e7ecf1 !important;
  }
</style>