<template>
  <ConditionFilter :filterConfig="filterConfig" @doQuery="search" :show-date-ranger="true" :do-query-when-start="true"
                   :close-after-query="true" ref="conditionFilter"></ConditionFilter>
  <div v-loading="loading">
    <ListHeaderTabs :default-tab-id="1" :configs="tabsConfig" @tab-change="search" ref="tabFilter"></ListHeaderTabs>
    <div class="table">
      <el-table
          :data="listData"
          style="width: 100%"
          @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="50" align="center">
        </el-table-column>
        <el-table-column prop="orderNo" label="订单号" align="left" header-align="left" width="200">
          <template #default="scope">
            {{ scope.row.orderNo }}
            <span v-if="currentTab===''" >
              <br />
              <span v-if="scope.row.assignStatus==='UN_ASSIGNED'" class="akz-tag info">未报送</span>
              <span v-if="scope.row.assignStatus==='ASSIGNED'" class="akz-tag primary">已报送</span>
              <span v-if="scope.row.assignStatus==='RETURNING'" class="akz-tag warning">退回中</span>
              <span v-if="scope.row.assignStatus==='CONFIRMED'" class="akz-tag success">已确认</span>
              <span v-if="scope.row.assignStatus==='FREEZE'" class="akz-tag deep">冻结</span>
            </span>
            <span v-if="currentTab!==''" >
              <br />
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="customerName" label="姓名" align="center" width="60">
        </el-table-column>
        <el-table-column prop="__description" label="备注" align="left" header-align="left" >
        </el-table-column>
        <el-table-column prop="__dealTime" label="报名时间" align="center" width="160">
        </el-table-column>
        <el-table-column label="操作" width="200" align="left">
          <template #default="scope">
            <el-button link class="akz-link" @click="edit(scope.row.id)">详情</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-item">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="record.currentIndex"
          :page-sizes="[20, 50, 100]"
          :page-size="record.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="record.total"></el-pagination>
    </div>
  </div>
</template>

<script>
import ListHeaderTabs from "@/components/frame/tabs/ListHeaderTabs";
import dateFormat from "@/components/util/DateFormat";
import OssBaseOrderApi from "@/api/OssBaseOrderApi";
import OssOrderTrainingApi from "@/api/OssOrderTrainingApi";
import {ref} from "vue";

export default {
  name: "AccountBill",
  components: {ListHeaderTabs },
  emits: ["goto-module"],
  mixins:[dateFormat,OssBaseOrderApi,OssOrderTrainingApi],
  setup() {
    const conditionFilter = ref(null)
    const tabFilter = ref(null)
    return {
      conditionFilter,tabFilter
    }
  },
  data() {
    return {
      loading:false,
      currentTab:"DRAFT",
      record: {
        list: [],
        currentIndex: 1,
        total: 100,
        pageSize: 20,
        selected: [],
        statistic:{
          startTime:'2000-01-01 00:00:00',
          endTime:'2000-01-01 00:00:00',
          orderCount: 0,
          preAmount: 0,
          amount: 0
        }
      },
      filterConfig: [
        {
          type: 'input',
          title: '供应商',
          placeHolder: '请输入...',
          bindField: 'supplierId',
          showInMain: true
        }
      ],
      tabsConfig: [
        {id: 1, tabName: "草稿",value : "DRAFT"},
        {id: 2, tabName: "待审核",value : "IN_REVIEW"},
        {id: 3, tabName: "已审核",value : "APPROVED"},
        {id: 4, tabName: "全部",value : ""}
      ]
    }
  },
  methods:{
    doQuery(filters) {
      //获取分页信息
      const _currentIndex = this.$data.record.currentIndex
      const _pageSize = this.$data.record.pageSize
      this.$data.loading = true

      filters["orderStatus"] = "PAY"

      //获取页大小和页数
      this.apiListBaseOrder(filters, _pageSize, _currentIndex).then((response) => {
        this.$data.loading = false
        this.$data.record.total = response.data.total
        this.$data.record.list = response.data.data ? response.data.data : []
      })
    },
    search() {
      const filters = this.conditionFilter.getFilters()
      const tabIndex = this.tabFilter.getFilters()
      let tabFilterValue
      this.$data.tabsConfig.forEach(x=>{
        if(x.id === tabIndex){
          tabFilterValue = x.value
        }
      })
      this.$data.currentTab = tabFilterValue
      if(tabFilterValue !== ""){
        filters["assignStatus"] = tabFilterValue
      }

      this.doQuery(filters)
    },
    handleSizeChange(pageSize) {
      this.record.pageSize = pageSize
      this.search()
    },
    handleCurrentChange(pageIndex) {
      this.record.currentIndex = pageIndex
      this.search()
    },
    handleSelectionChange(changed) {
      this.$data.record.selected = changed.map(x => x.orderNo)
    },
    edit(id){
      console.log(id)
    }
  },
  computed:{
    listData: function () {
      return this.$data.record.list.map((row) => {

        return row
      })
    }
  }
}
</script>

<style scoped>

</style>