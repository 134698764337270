import request from "@/components/util/http";

export default {
    methods:{
        apiSeries(){
            return request({
                url: '/api/product/oss/product-series/select-enabled',
                method: 'get'
            })
        }
    }
}