<template>
  <el-dialog
      v-model="dialogVisible"
      title="选择供应商"
      width="30%" custom-class="class-choose-search" @opened="onopen">
    <el-scrollbar height="200px" always="true" v-if="list.length>0">
      <div class="akz-scroll-item" v-for="item in list" :key="item.id" @click="choose(item.id,item.supplierName)">
        <span v-if="item.id===selected" class="item-selected">{{ item.supplierName }}</span>
        <span v-else>{{ item.supplierName }}</span>
      </div>
    </el-scrollbar>
    <div v-else>
      <el-empty description="没有数据" />
    </div>
    <template #footer>
      <span class="dialog-footer">
        <span style="float: left;width: 200px; text-align: left;font-size: 12px; line-height: 28px;">已选择:
          <span v-if="selected===-1">{{ selectedName }}</span>
          <span style="color:#00CA7A" v-if="selected!==-1">{{ selectedName }}</span>
        </span>
        <el-button @click="closePopup" link class="akz-btn" style="color: #A4A4A4;font-weight: 400">取消</el-button>
        <el-button type="primary" @click="doConfirm" class="akz-btn">选择</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import {ElMessage as Message} from 'element-plus'
import SupplierApi from "@/api/SupplierApi";

export default {
  name: "SupplierSelect",
  mixins:[ SupplierApi ],
  setup() {
  },
  data() {
    return {
      dialogVisible: false,
      selected: -1,
      selectedName: "未选择",
      searchInfo: "",
      status: "SIGN_UP",
      list: [],
      candidateIds:[]
    }
  },
  props: {
    confirmAction: Function
  },
  methods: {
    setVisible: function (value) {
      this.$data.dialogVisible = value
    },
    setCandidateIds: function (ids){
      this.$data.candidateIds = ids?ids:[]
    },
    closePopup() {
      this.setVisible(false)
    },
    doConfirm() {
      if(this.$data.selected === -1){
        Message.warning("请选择要报送的供应商")
        return
      }
      if (this.confirmAction) {
        this.confirmAction(this.$data.candidateIds,this.$data.selected)
      }
      this.setVisible(false)
      this.$data.selected = -1
      this.$data.selectedName = "未选择"
    },
    onopen() {
      this.search()
    },
    search() {
      this.apiListForSelect().then((response) => {
        if(!response.data || response.data === null){
          this.$data.list = []
        }else{
          this.$data.list = response.data
          if(this.$data.list.length > 0){
            this.choose(this.$data.list[0].id,this.$data.list[0].supplierName)
          }
        }
      })
    },
    choose(id, name){
      this.$data.selected = id
      this.$data.selectedName = name
    }
  },
}
</script>

<style scoped>
  .class-choose-search .el-dialog__body {
    padding-top: 0 !important;
  }

  .akz-scroll-item {
    font-size: 12px;
    cursor: pointer;
  }
  .akz-scroll-item span{
    padding: 8px;
    display: block;
  }
  .akz-scroll-item span:hover{
    background-color: #f1f1f1;
    color: #00CA7A !important;
  }
  .akz-scroll-item .item-selected{
    background-color: #00CA7A;
    color: white;
  }
</style>