<template>
  <ConditionFilter :filterConfig="filterConfig" @doQuery="search" :show-date-ranger="false" :do-query-when-start="true"
                   :close-after-query="true" ref="conditionFilter"></ConditionFilter>
  <el-button type="primary" @click="openPopUp" style="margin-bottom: 8px;">批量导入</el-button>
  <ListHeaderTabs :default-tab-id="1" :configs="tabsConfig" @tab-change="search" ref="tabFilter"></ListHeaderTabs>
  <div class="table">
    <el-table
        :data="listData"
        style="width: 100%"
        @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="50" align="center">
      </el-table-column>
      <el-table-column prop="idCardNo" label="身份证号" align="center" width="200">
      </el-table-column>
      <el-table-column prop="__createTime" label="导入时间" align="center">
      </el-table-column>
      <el-table-column label="操作" width="200" align="left">
        <template #default="scope">
          <el-button link class="akz-link" @click="remove(scope.row.idCardNo)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
  <div class="table-multi-btns">
    <span>批量操作：</span>
    <el-button link class="akz-link" @click="removeBatch">删除</el-button>
  </div>
  <div class="pagination-item">
    <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="record.currentIndex"
        :page-sizes="[20, 50, 100]"
        :page-size="record.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="record.total"></el-pagination>
  </div>

  <!-- 导入 -->
  <el-dialog v-model="showImportDialog" title="批量导入" custom-class="akz-popup"
             @close="closePopup" :destroy-on-close="true">

    <el-upload class="upload-demo" drag action="/api/samp/oss/xh-id-card-limited/import" @success="search">
      <el-icon class="el-icon--upload"><upload-filled /></el-icon>
      <div class="el-upload__text">
        拖动文件到这里 或 <em>点击选取</em>
      </div>
      <template #tip>
        <div class="el-upload__tip" style="margin-top: 20px;">
          支持.xlsx类型且文件小于500kb
          <el-button link class="akz-link" @click="downloadTemplate">导入模版文件下载<el-icon class="el-icon--right"><Download /></el-icon></el-button>
        </div>
      </template>
    </el-upload>
  </el-dialog>
</template>

<script>
import {ref} from "vue";
import ListHeaderTabs from "@/components/frame/tabs/ListHeaderTabs";
import XhIdCardLimitedApi from "@/api/XhIdCardLimitedApi";
import DateFormat from "@/components/util/DateFormat";
import {ElMessage as Message} from 'element-plus'
import axios from "axios";

export default {
  name: "XhIdCardMng",
  components: {ListHeaderTabs },
  mixins:[ XhIdCardLimitedApi,DateFormat ],
  setup() {
    const conditionFilter = ref(null)
    const tabFilter = ref(null)
    return {
      conditionFilter,tabFilter
    }
  },
  data() {
    return {
      loading:false,
      record: {
        list: [],
        currentIndex: 1,
        total: 100,
        pageSize: 20,
        selected: [],
        statistic:{
          startTime:'2000-01-01 00:00:00',
          endTime:'2000-01-01 00:00:00',
          orderCount: 0,
          preAmount: 0,
          amount: 0
        }
      },
      filterConfig: [
        {
          type: 'input',
          title: '身份证号',
          placeHolder: '请输入...',
          bindField: 'idCardNo',
          showInMain: true
        }
      ],
      tabsConfig: [
        {id: 1, tabName: "有效",value : "ENABLE"},
        {id: 2, tabName: "失效",value : "DISABLE"},
        {id: 3, tabName: "全部",value : ""}
      ],
      showImportDialog: false
    }
  },
  methods:{
    doQuery(filters) {
      //获取分页信息
      const _currentIndex = this.$data.record.currentIndex
      const _pageSize = this.$data.record.pageSize
      this.$data.loading = true

      //获取页大小和页数
      this.apiListXhIdCardLimited(filters, _pageSize, _currentIndex).then((response) => {
        this.$data.loading = false
        this.$data.record.total = response.data.total
        this.$data.record.list = response.data.data ? response.data.data : []
      })
    },
    search() {
      const filters = this.conditionFilter.getFilters()
      const tabIndex = this.tabFilter.getFilters()
      let tabFilterValue
      this.$data.tabsConfig.forEach(x=>{
        if(x.id === tabIndex){
          tabFilterValue = x.value
        }
      })
      this.$data.currentTab = tabFilterValue
      if(tabFilterValue !== ""){
        filters["validate"] = tabFilterValue==="ENABLE"
      }

      this.doQuery(filters)
    },
    handleSizeChange(pageSize) {
      this.record.pageSize = pageSize
      this.search()
    },
    handleCurrentChange(pageIndex) {
      this.record.currentIndex = pageIndex
      this.search()
    },
    handleSelectionChange(changed) {
      this.$data.record.selected = changed.map(x => x.idCardNo)
    },
    remove(idCardNo){
      this.apiRemoveIdCardLimited(idCardNo).then(()=>{
        this.search()
      })
    },
    removeBatch(){
      if(this.$data.record.selected.length === 0){
        Message.warning("请选择要删除的数据")
        return
      }
      this.apiRemoveIdCardLimitedBatch(this.$data.record.selected).then(()=>{
        this.search()
      })
    },

    /**
     * 导入
     */
    openPopUp(){
      this.$data.showImportDialog = true
    },
    closePopup(){
      this.$data.showImportDialog = false
    },
    downloadTemplate(){
      axios.get('template/IdCardImportTemplate.xlsx', {   //静态资源文件夹public
        responseType: 'blob',
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        let fileName = 'IdCardImportTemplate.xlsx';
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
      }).catch(error => {
        console.log('error:'+JSON.stringify(error))
      });
    }
  },
  computed:{
    listData: function () {
      return this.$data.record.list.map((row) => {
        row.__createTime = this.dateFormat(row.createTime)
        return row
      })
    }
  }
}
</script>

<style scoped>

</style>