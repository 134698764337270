import request from "@/components/util/http";

export default {
    methods:{
        apiLoadCategories(seriesCode,industryCode){
            return request({
                url: '/api/product/oss/product-category/select-enabled/'+seriesCode+"/"+industryCode,
                method: 'get'
            })
        },
        apiLoadForFamily(categoryNo){
            return request({
                url: '/api/product/oss/product-category/family/'+categoryNo,
                method: 'get'
            })
        }
    }
}