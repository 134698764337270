<template>
  <router-view></router-view>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style>
  .akz-input .el-input__wrapper,.akz-input .el-input-group__append{
    border-radius: 0;
  }
</style>
