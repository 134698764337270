import request from "@/components/util/http";
import Storage from "@/components/util/Storage";

export default {
    mixins:[ Storage ],
    methods:{
        apiListBindUsers(phone){
            return request({
                url : '/api/exam/oss/wx-mp/user/phone/'+phone,
                method: 'get',
            })
        }
    }
}