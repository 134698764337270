<template>
  <el-dialog v-model="showPopupWindow" title="地区选择" custom-class="akz-popup product-select"
             @close="closePopup" :destroy-on-close="true">
    <el-scrollbar height="350px" always>
    <div class="akz-area-item" v-for="area in areas" :key="area.areaCode"
         @click="currentAreaCode=area.areaCode"
         :class="currentAreaCode===area.areaCode?'akz-area-item-selected':''">
      {{ area.areaName }}
    </div>
    </el-scrollbar>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="closePopup" link class="akz-btn" style="color: #A4A4A4;font-weight: 400">取消</el-button>
        <el-button type="primary" @click="doConfirm" class="akz-btn" >确认</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import AreaApi from "@/api/AreaApi";
import { ElMessage as Message } from "element-plus"

export default {
  name: "AreaSelect",
  emits:["close-popup"],
  mixins:[ AreaApi ],
  data(){
    return {
      showPopupWindow : false,
      currentAreaCode:'',
      areas:[]
    }
  },
  methods:{
    openPopup(currentAreaCode){
      this.loadAreaData()
      this.$data.showPopupWindow = true
      this.$data.currentAreaCode = currentAreaCode
    },
    closePopup: function () {
      this.$data.showPopupWindow = false
    },
    doConfirm() {
      this.$data.showPopupWindow = false

      const currentArea =  this.getForCode(this.$data.currentAreaCode)
      if(!currentArea){
        Message.error("请选择正确的地区")
        return
      }

      this.$emit("close-popup",{
        code: this.$data.currentAreaCode,
        name: currentArea.areaName
      })
    },
    loadAreaData(){
      this.apiAreas().then((res)=>{
        this.$data.areas = res.data
      })
    },
    getForCode(code){
      for(let area of this.$data.areas ){
        if(area.areaCode === code){
          return area
        }
      }
    }
  }
}
</script>

<style scoped>
  .akz-area-item{
    padding: 5px;
    font-size: 12px;
    cursor: pointer;
  }
  .akz-area-item:hover{
    color: #00CA7A;
  }
  .akz-area-item.akz-area-item-selected{
    background-color: #00CA7A;
    color: white;
  }
</style>