import request from "@/components/util/http";


export default {
    methods:{
        apiListXhIdCardLimited(filters,pageSize,pageIndex){
            return request({
                url : '/api/samp/oss/xh-id-card-limited/list/' + pageSize + '/' + pageIndex,
                method: 'get',
                params: filters
            })
        },
        apiRemoveIdCardLimited(idCardNo){
            return this.apiRemoveIdCardLimitedBatch([idCardNo])
        },
        apiRemoveIdCardLimitedBatch(idCardNos){
            return request({
                url : '/api/samp/oss/xh-id-card-limited',
                method: 'delete',
                data: idCardNos
            })
        }
    }
}