import request from "@/components/util/http";

export default {
    methods:{
        apiUpdatePersonInfo(orderNo,params){
            return request({
                url : '/api/shopping/oss/order-training/' + orderNo + "/person",
                method: 'put',
                data: params
            })
        },
        apiUpdateCompanyInfo(orderNo,params){
            return request({
                url : '/api/shopping/oss/order-training/' + orderNo + "/company",
                method: 'put',
                data: params
            })
        },
        apiUpdatePostInfo(orderNo,params){
            return request({
                url : '/api/shopping/oss/order-training/' + orderNo + "/post",
                method: 'put',
                data: params
            })
        },
        apiDownloadOrderContent(orderNos){
            return request({
                url: '/api/shopping/oss/cq-peixun-order/download',
                method:'post',
                data:{
                    orderNos : orderNos
                },
                responseType: 'blob'
            })
        },
        apiResetUserInfo(orderNo){
            return request({
                url : '/api/shopping/oss/order/' + orderNo + "/reset",
                method: 'put'
            })
        }
    }
}