<template>
  <el-dialog v-model="showPopupWindow" :title="popTitle" custom-class="akz-popup"
             @close="closePopup" @open="resetForm" :destroy-on-close="true">
    <el-form ref="ruleFormRef" :model="formData" :rules="formRules" label-position="center" :label-width="labelWidth"
             class="akz-popup-form" status-icon>
      <el-form-item v-for="field in controlConfigs" :label="field.title" :prop="field.bindField"
                    :key="field.fieldName">
        <!--普通输入框-->
        <el-input v-model="formData[field.bindField]" v-if="field.type === 'input'"
                  :placeholder="field.placeHolder"></el-input>
        <!--选择输入框-->
        <el-select v-model="formData[field.bindField]" v-if="field.type === 'select'"
                   style="width:100%"
                   :placeholder="field.placeHolder">
          <el-option v-for="option in field.options" :label="option.label" :value="option.value"
                     :key="option.value"/>
        </el-select>
        <!--日期时间选择器-->
        <el-date-picker v-model="formData[field.bindField]"
                        v-if="field.type === 'datetime'" value-format="x" style="width:100%" type="datetime"
                        :placeholder="field.placeHolder"/>
        <el-date-picker v-model="formData[field.bindField]"
                        v-if="field.type === 'date'" value-format="x" style="width:100%" type="date"
                        :placeholder="field.placeHolder"/>
        <!-- switch -->
        <el-switch v-model="formData[field.bindField]" size="large" v-if="field.type === 'switch'"
                   inline-prompt :active-text="field.trueText" :inactive-text="field.falseText"/>
        <!--area-->
        <el-input v-model="formData[field.bindField]" v-if="field.type === 'area'" :rows="field.rowCount"
                  :show-word-limit="field.maxLength?true:false" :maxlength="field.maxLength?field.maxLength:100000"
                  type="textarea" :placeholder="field.placeHolder"/>
        <!--pwd-->
        <el-input v-model="formData[field.bindField]" v-if="field.type === 'input-pwd'" type="password"
                  :placeholder="field.placeHolder" show-password/>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="closePopup" link class="akz-btn" style="color: #A4A4A4;font-weight: 400">{{
            cancelBtnText
          }}</el-button>
        <el-button type="primary" @click="doConfirm" class="akz-btn">{{ confirmBtnText }}</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import {ref} from "vue"

export default {
  name: "PopupForm",
  emits: ["close-popup"],
  props: {
    showPopup: {
      type: Boolean,
      default: false
    },
    labelWidth: {
      type: String,
      default: "80px"
    },
    popTitle: {
      type: String,
      default: ""
    },
    confirmBtnText: {
      type: String,
      default: "确认"
    },
    cancelBtnText: {
      type: String,
      default: "取消"
    },
    confirmAction: Function,
    formConfig: Object
  },
  setup() {
    const ruleFormRef = ref(null)
    return {
      ruleFormRef
    }
  },
  data() {
    return {
      formData: {},
      controlConfigs: [],
      formRules: {}
    }
  },
  methods: {
    doConfirm: function () {
      this.ruleFormRef.validate((valid) => {
        if (valid) {
          this.confirmAction(this.$data.formData)
        } else {
          return
        }
      })
    },
    closePopup: function () {
      this.$emit("close-popup")
    },
    resetForm:function (){
      if(this.ruleFormRef){
        this.ruleFormRef.resetFields()
      }
    },
    initialData: function (form){
      if(form){
        for(let key in form){
          this.$data.formData[key] = form[key]
        }
      }
    }
  },
  mounted() {
    const _rules = {}
    this.$data.controlConfigs = this.$props.formConfig.map((x) => {
      //初始化筛选控件绑定字段
      if(typeof x.defaultValue !== "undefined"){
        this.$data.formData[x.bindField] = x.defaultValue
      }

      if (x.rules) {
        _rules[x.bindField] = x.rules
      }
      return x
    })
    this.$data.formRules = _rules
  },
  computed: {
    showPopupWindow() {
      return this.$props.showPopup
    }
  }
}
</script>

<style scoped>
.akz-btn {
  padding-left: 30px;
  padding-right: 30px;
  font-weight: 40;
}
</style>