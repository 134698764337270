<template>
  <div class="common-layout">
    <el-container>
      <el-header height="56px" :style="{
      zIndex: 2,
      padding:'0 0 0 23px',
      backgroundColor:'white',
      boxShadow: `var(${getCssVarName('light')})`}">
        <el-image :src="require('@/assets/logo.png')" class="logo-pan"></el-image>
<!--        <el-image src="require('/assets/logo.png')" class="logo-pan"></el-image>-->
      </el-header>
      <el-main>
        <el-row style="width: 880px;margin: 30px auto;background-color: white">
          <el-col :span="10">
            <div class="user-login" v-show="loginType==='QR_CODE'">
              <div class="user-login-type user-login-type-active">企业微信登录</div>
              <div class="user-login-type" @click="changeLoginType('PWD')">帐号密码登录</div>
              <el-row>
                <el-col :span="24">
                  <div class="qr_login" id="qr_login"></div>
                </el-col>
              </el-row>
            </div>
            <div v-show="loginType==='PWD'">
              <div class="user-login">
                <div class="user-login-type" @click="changeLoginType('QR_CODE')">企业微信登录</div>
                <div class="user-login-type user-login-type-active">帐号密码登录</div>
                <el-row>
                  <el-col :span="24">
                    <el-input
                        v-model="userName"
                        ref="userNameRef"
                        class="w-50 m-2"
                        size="large"
                        placeholder="输入用户名"
                    >
                      <template #prefix>
                        <el-icon class="el-input__icon"><Avatar /></el-icon>
                      </template>
                    </el-input>
                  </el-col>
                </el-row>
                <el-row class="form-item">
                  <el-col :span="24">
                    <el-input
                        v-model="pwd" show-password
                        ref="pwdRef"
                        class="w-50 m-2"
                        size="large"
                        placeholder="输入登录密码"
                    >
                      <template #prefix>
                        <el-icon class="el-input__icon"><Lock /></el-icon>
                      </template>
                    </el-input>
                  </el-col>
                </el-row>
                <el-row class="form-item">
                  <el-col :span="16">
                    <el-input
                        v-model="validateCode"
                        ref="validateCodeRef"
                        class="w-50 m-2"
                        size="large"
                        placeholder="输入图片验证码"
                        @keyup.enter="login"
                    />
                  </el-col>
                  <el-col :span="8">
                    <el-image style="width: 100%; height: 40px" :src="validateCodeUrl" @click="refreshImg" fit="fill" />
                  </el-col>
                </el-row>
              </div>
              <el-row v-if="loginType==='PWD'&&errorDesc.length>0" class="form-item" style="margin-bottom: 20px">
                <el-col :span="24">
                  <div class="error-msg">{{ errorDesc }}</div>
                </el-col>
              </el-row>
              <el-row class="form-item" v-if="loginType==='PWD'" style="margin-bottom: 20px">
                <el-col :span="24">
                  <el-button type="primary" size="large" :loading="isLoading" style="width: 100%" @click="login">登 录</el-button>
                </el-col>
              </el-row>
            </div>
            <el-row>
              <el-col :span="24">
                <div class="qAndA">
                  <div class="qAndA-q">登录遇到问题？</div>
                  <div class="qAndA-a">忘记密码请联系管理员进行重置</div>
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <div class="qAndA">
                  <div class="qAndA-q">系统遇到问题？</div>
                  <div class="qAndA-a">若遇到不可恢复的技术问题，请联系我们的客服。<br />联系电话：<span style="color: #219b6f">(023)8658 9557</span> (8:30~17:30)</div>
                </div>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="14">
            <div style="width: 100%;height: 100%;margin-left: 20px">
              <el-image :src="require('@/assets/login_bg.jpeg')" fit="fill"></el-image>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <div style="text-align: center" class="site-backup">
              <div>
                Copyright 2020-2023 重庆中建英才企业管理咨询有限公司 版权所有
              </div>
              <div>
                渝ICP备18006070号-1
              </div>
            </div>
          </el-col>
        </el-row>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import {uuid} from "vue-uuid";
import {ref} from 'vue';
import AES from '@/components/util/Aes';
import LoginApi from "@/api/LoginApi";
import Storage from "@/components/util/Storage";
import CookiesUtil from "@/components/util/CookiesUtil";
import {useRouter} from "vue-router";

export default {
  name: "UserLogin",
  mixins:[ LoginApi,Storage,CookiesUtil ],
  setup(){
    const userNameRef = ref(null)
    const pwdRef = ref(null)
    const validateCodeRef = ref(null)
    const router = useRouter()
    return {
      userNameRef,pwdRef,validateCodeRef,router
    }
  },
  data(){
    return {
      loginType:'QR_CODE',
      userName:'',
      pwd:'',
      validateCode:'',
      validateTag:'',
      random: '',
      isLoading: false,
      errorDesc: ''
    }
  },
  methods:{
    getCssVarName(type) {
      return `--el-box-shadow${type ? '-' : ''}${type}`
    },
    refreshImg(){
      this.$data.random = uuid.v1();
    },
    login(){
      //判断不能为空
      const userName = this.$data.userName
      const pwd = this.$data.pwd
      const validateCode = this.$data.validateCode
      if(this.$data.userName === ""){
        this.userNameRef.focus();
        return
      }
      if(this.$data.pwd === ""){
        this.pwdRef.focus()
        return
      }
      if(this.$data.validateCode === ""){
        this.validateCodeRef.focus()
        return
      }
      this.$data.errorDesc = ""
      this.$data.isLoading = true
      this.doLogin(userName,AES.encrypt(pwd),validateCode,this.$data.validateTag).then((response) => {
        const { nickName,token,roles } = response.data

        this.$data.isLoading = false

        this.setItem("_nickName",nickName?nickName:"")
        this.setItem("_token",token?token:"")
        this.setItem("_roles",roles?JSON.stringify(roles):"")
        this.setCookie("user_login_token",token)

        this.router.push("/")
      },error =>{
        this.$data.errorDesc = error.message
        this.$data.isLoading = false
        this.refreshImg()
      })
    },
    changeLoginType(type){
      this.$data.loginType = type
      if("PWD"===type){
        this.userNameRef.focus();
      }
    }
  },
  mounted() {
    this.$data.validateTag = uuid.v1();
    this.$data.random = uuid.v1();

    new window.WwLogin({
      "id": "qr_login",
      "appid": "wwb7bb3c1c5215f367",
      "agentid": "1000002",
      "redirect_uri": encodeURI('https://oss.zjycloud.com/api/wx-cp-user/login'),
      "state": "",
      "href": "https://oss.zjycloud.com/login_qr_code.css",
      "lang": "zh",
    });
  },
  computed:{
    validateCodeUrl : function(){
      return "/api/oss-user/validate-img/" + this.$data.validateTag + "?rd="+this.$data.random;
    }
  }
}
</script>

<style scoped>
  .user-login{
    font-size: 12px;
  }
  .user-login .user-login-type{
    font-size: 18px;
    border-bottom: 2px solid transparent;
    display: inline-block;
    margin-bottom: 30px;
    margin-right: 28px;
    cursor: pointer;
  }
  .user-login .user-login-type.user-login-type-active{
    border-bottom: 2px solid #219b6f !important;
    color:#219b6f !important;
  }
  .el-input__inner,.el-input--large{
    font-size: 12px !important;
  }
  .form-item{
    margin-top:10px;
  }
  .qAndA{
    margin-top: 30px;
  }
  .qAndA .qAndA-q{
    font-size: 14px;
    margin-bottom: 10px;
  }
  .qAndA .qAndA-a{
    font-size: 12px;
    color: #A4A4A4;
  }
  .error-msg{
    font-size: 12px;
    color: #FF0000;
  }
  .site-backup{
    color: #7F7F7F;
    font-size: 12px;
    margin-top: 20px;
    line-height: 25px;
  }
  .qr_login{
    height: 220px;
    overflow: hidden;
  }
</style>