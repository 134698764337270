<template>
  <!--  详情  -->
  <el-drawer v-model="show" :show-close="true" custom-class="detail-draw-pan" size="70%" :destroy-on-close="true"
             @close="closeDetail">
    <template #header="{titleId, titleClass }">
      <h4 :id="titleId" :class="titleClass" style="color: #000;font-weight: 650;font-size: 14px;">
        <Tickets style="width: 16px; height: 16px;"/>
        <span style="margin-left: 8px;">报名详情</span></h4>
    </template>
    <div v-loading="loading">
      <div v-if="(order.orderStatus==='PAY'||order.orderStatus==='PART_REFUND') && (order.assignStatus==='UN_ASSIGNED' || order.assignStatus==='RETURNING'|| order.assignStatus==='FREEZE')" class="akz-pan"
           style="padding:15px;display: flex;justify-content: space-between">
        <el-popconfirm confirm-button-text="确定"
                       cancel-button-text="取消"
                       title="是否确认报送供应商?"
                       @confirm="openSupplierChoose">
          <template #reference>
            <el-button type="primary">报送</el-button>
          </template>
        </el-popconfirm>
        <div style="display: inline-block;line-height: 32px;">
          <el-button link type="info" @click="openRefundPopup">订单退款</el-button>
          <el-button link type="info" v-if="order.assignStatus!=='FREEZE'" @click="freezeOrder">冻结</el-button>
          <el-button link type="info" v-if="order.assignStatus==='FREEZE'" @click="unfreezeOrder">解冻</el-button>

          <el-popconfirm confirm-button-text="确定"
                         cancel-button-text="取消"
                         title="重置操作会丢失已编辑信息，是否继续重置为用户填写信息?"
                         @confirm="doResetUserInfo">
            <template #reference>
              <el-button link type="info" @click="unfreezeOrder">重置用户信息</el-button>
            </template>
          </el-popconfirm>
        </div>
      </div>
      <div class="akz-pan">
        <div class="akz-pan-title">
          订单报送信息
        </div>
        <div class="akz-pan-content">
          <el-row :gutter="10">
            <el-col :span="4" :class="'field-name'">
              报送状态
            </el-col>
            <el-col :span="20">
              <span v-if="order.assignStatus==='UN_ASSIGNED'" class="akz-tag info">未报送</span>
              <span v-if="order.assignStatus==='ASSIGNED'" class="akz-tag primary">已报送</span>
              <span v-if="order.assignStatus==='RETURNING'" class="akz-tag warning">退回中</span>
              <span v-if="order.assignStatus==='CONFIRMED'" class="akz-tag success">已确认</span>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="4" :class="'field-name'">
              报送供应商
            </el-col>
            <el-col :span="20">
              {{ order.assignSupplierName }}
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="akz-pan">
        <div class="akz-pan-title">
          订单信息
        </div>
        <div class="akz-pan-content">
          <el-row :gutter="10">
            <el-col :span="4" :class="'field-name'">
              订单号
            </el-col>
            <el-col :span="20">
              {{ order.orderNo }}
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="4" :class="'field-name'">
              付款时间
            </el-col>
            <el-col :span="20">
              {{ dealTime }}
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="4" :class="'field-name'">
              报名联系电话
            </el-col>
            <el-col :span="20">
              {{ order.wxBindPhone }}
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="4" :class="'field-name'">
              地区/商品分类/商品名称
            </el-col>
            <el-col :span="20">
              {{ order.areaName }} / {{ order.seriesName }} / {{ order.productName }}
              <el-popconfirm confirm-button-text="确定"
                             cancel-button-text="取消"
                             title="更改订单商品涉及结算价格调整，是否继续更改?"
                             @confirm="openProductSelect">
                <template #reference>
                  <el-button link class="akz-link" >更改</el-button>
                </template>
              </el-popconfirm>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="4" :class="'field-name'">
              备注
            </el-col>
            <el-col :span="20">
              <span v-if="order.description" style="word-break: break-all">
                {{ order.description }}
              </span>
              <span v-else style="color:#c2c2c2">
                未备注
              </span>
              <el-button link class="akz-link" @click="openUpdateDescription">编辑</el-button>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="akz-pan" v-if="order.orderStatus==='PAY'||order.orderStatus==='PART_REFUND'">
        <div class="akz-pan-title">
          模拟考试开通
        </div>
        <div class="akz-pan-content">
          <el-row :gutter="10">
            <el-col :span="4" :class="'field-name'">
              已绑定账号
            </el-col>
            <el-col :span="20">
              <el-card class="box-card" v-for="item in bindUser" :key="item.phone" shadow="hover">
                <template #header>
                  <div class="card-header">
                    <span>{{item.phone}}</span>
                  </div>
                </template>
                <div class="exam-auth" v-for="i in item.data" :key="i.userId">
                  <div class="exam-auth-account">{{i.wxName}}</div>
                  <el-avatar :size="52" :src="i.profileImg" />
                  <el-popconfirm confirm-button-text="开通"
                                 cancel-button-text="取消"
                                 title="是否为该账号开通模拟考试权限?"
                                 @confirm="doAuthExam(i.userId)">
                    <template #reference>
                      <el-button link class="akz-link">开通</el-button>
                    </template>
                  </el-popconfirm>
                  <el-popconfirm confirm-button-text="开通"
                                 cancel-button-text="取消"
                                 title="是否为该账号关闭模拟考试权限?"
                                 @confirm="doUnAuthExam">
                    <template #reference>
                      <el-button link class="akz-link">关闭</el-button>
                    </template>
                  </el-popconfirm>
                </div>
                <div v-if="!item.data || item.data.length===0">
                  公众号还没有绑定
                </div>
              </el-card>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="akz-pan">
        <div class="akz-pan-title">
          个人信息
          <el-button link class="akz-link" v-if="order.orderStatus==='PAY'||order.orderStatus==='PART_REFUND'" @click="openPersonInfoUpdate">编辑</el-button>
        </div>
        <div class="akz-pan-content">
          <el-row :gutter="10">
            <el-col :span="4" :class="'field-name'">
              姓名
            </el-col>
            <el-col :span="20">
              {{ person.trueName }}
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="4" :class="'field-name'">
              联系电话
            </el-col>
            <el-col :span="20">
              {{ person.phone }}
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="4" :class="'field-name'">
              身份证号
            </el-col>
            <el-col :span="20">
              {{ person.idCardNo }}
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="4" :class="'field-name'">
              身份证
            </el-col>
            <el-col :span="20">
              <div class="id-card-img">
                <div class="id-card-name">身份证正面</div>
                <el-upload
                    class="upload-demo"
                    :action="updateCardFrontPath"
                    :auto-upload="true"
                    name="fileName"
                    @success="cardFrontUpdateSuccess"
                    :before-upload="beforePickUpdate"
                >
                  <template #trigger>
                    <el-image :src="idCardFront" loading="eager">
                      <template #placeholder>
                        <div class="image-slot">加载中<span class="dot">...</span></div>
                      </template>
                      <template #error>
                        <div class="image-slot">
                          <el-icon>
                            <UploadFilled/>
                          </el-icon>
                          <div class="id-card-img-des">点击上传</div>
                        </div>
                      </template>
                    </el-image>
                  </template>
                  <template #tip>
                    <div class="el-upload__tip">
                      *选择小于500kb的jpg/png文件进行上传
                    </div>
                  </template>
                </el-upload>

              </div>
              <div class="id-card-img">
                <div class="id-card-name">身份证反面</div>
                <el-upload
                    class="upload-demo"
                    :action="updateCardBackPath"
                    :auto-upload="true"
                    name="fileName"
                    @success="cardBackUpdateSuccess"
                    :before-upload="beforePickUpdate"
                >
                  <template #trigger>
                    <el-image :src="idCardBack" loading="eager">
                      <template #placeholder>
                        <div class="image-slot">加载中<span class="dot">...</span></div>
                      </template>
                      <template #error>
                        <div class="image-slot">
                          <el-icon>
                            <UploadFilled/>
                          </el-icon>
                          <div class="id-card-img-des">点击上传</div>
                        </div>
                      </template>
                    </el-image>
                  </template>
                  <template #tip>
                    <div class="el-upload__tip">
                      *选择小于500kb的jpg/png文件进行上传
                    </div>
                  </template>
                </el-upload>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="4" :class="'field-name'">
              证件照
            </el-col>
            <el-col :span="20">
              <div class="id-card-img id-photo">
                <el-upload
                    class="upload-demo"
                    :action="updateIDPhotoPath"
                    :auto-upload="true"
                    name="fileName"
                    @success="idPhotoUpdateSuccess"
                    :before-upload="beforePickUpdate" >
                  <template #trigger>
                    <el-image :src="idPhoto" loading="eager">
                      <template #placeholder>
                        <div class="image-slot">加载中<span class="dot">...</span></div>
                      </template>
                      <template #error>
                        <div class="image-slot">
                          <el-icon>
                            <UploadFilled/>
                          </el-icon>
                          <div class="id-card-img-des">点击上传</div>
                        </div>
                      </template>
                    </el-image>
                  </template>
                  <template #tip>
                    <div class="el-upload__tip">
                      *选择小于500kb的jpg/png文件进行上传
                    </div>
                  </template>
                </el-upload>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="akz-pan">
        <div class="akz-pan-title">
          单位信息
          <el-button link class="akz-link" v-if="order.orderStatus==='PAY'||order.orderStatus==='PART_REFUND'" @click="openCompanyInfoUpdate">编辑</el-button>
        </div>
        <div class="akz-pan-content">
          <el-row :gutter="10">
            <el-col :span="4" :class="'field-name'">
              单位名称
            </el-col>
            <el-col :span="20">
              {{ company.company }}
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="4" :class="'field-name'">
              纳税人识别号
            </el-col>
            <el-col :span="20">
              {{ company.taxNo }}
            </el-col>
          </el-row>
        </div>
      </div>
      <!--      <div class="akz-pan">
              <div class="akz-pan-title">
                开票信息
              </div>
              <div class="akz-pan-content">
                <el-row :gutter="10">
                  <el-col :span="4" :class="'field-name'">
                    发票类型
                  </el-col>
                  <el-col :span="20">
                    <el-button link class="akz-link">变更</el-button>
                  </el-col>
                </el-row>
                <el-row :gutter="10">
                  <el-col :span="4" :class="'field-name'">
                    电子邮箱
                  </el-col>
                  <el-col :span="20">
                    <el-button link class="akz-link">换绑</el-button>
                  </el-col>
                </el-row>
                <el-row :gutter="10">
                  <el-col :span="4" :class="'field-name'">
                    开票状态
                  </el-col>
                  <el-col :span="20">
                  </el-col>
                </el-row>
                <el-row :gutter="10">
                  <el-col :span="24">
                    <el-button type="default" style="margin-left: 0">开票确认</el-button>
                    <el-button type="default" link style="margin-left: 0">取消确认</el-button>
                    <span style="vertical-align: bottom;font-size: 12px;color: #A4A4A4;margin-left: 20px;line-height: 32px">确认开票后，开票状态变为「已开具」</span>
                  </el-col>
                </el-row>
              </div>
            </div>-->
      <div class="akz-pan">
        <div class="akz-pan-title">
          寄送信息
          <el-button link class="akz-link" v-if="order.orderStatus==='PAY'||order.orderStatus==='PART_REFUND'" @click="openPostInfoUpdate">编辑</el-button>
        </div>
        <div class="akz-pan-content">
          <el-row :gutter="10">
            <el-col :span="4" :class="'field-name'">
              是否自提
            </el-col>
            <el-col :span="20">
              {{ postStatus }}
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="4" :class="'field-name'">
              收件人
            </el-col>
            <el-col :span="20">
              {{ post.contactsName }}
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="4" :class="'field-name'">
              收件人电话
            </el-col>
            <el-col :span="20">
              {{ post.contactsPhone }}
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="4" :class="'field-name'">
              收件地址
            </el-col>
            <el-col :span="20">
              {{ post.address }}
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="4" :class="'field-name'">
              详细地址
            </el-col>
            <el-col :span="20">
              {{ post.roomNo }}
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="akz-pan">
        <div class="akz-pan-title">
          流程记录
        </div>
        <div class="akz-pan-content">
          <el-table style="width: 100%" :data="orderLogRender">
            <el-table-column prop="operatorName" label="处理人" align="left" header-align="left" width="200">
              <template #default="scope">
                <span v-if="scope.row.__operatorType!==''">{{ scope.row.__operatorType }} <span
                    v-if="scope.row.operatorType!=='CUSTOMER'">/</span></span>
                <span v-if="scope.row.operatorType!=='CUSTOMER'">{{ scope.row.operatorName }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="__createTime" label="处理时间" align="left" header-align="left" width="180">
            </el-table-column>
            <el-table-column prop="content" label="内容" align="left" header-align="left">
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </el-drawer>
  <PopupForm :show-popup="updateDescription.show" pop-title="编辑备注" :confirm-action="doUpdateOrderDescription"
             @close-popup="closeDescriptionUpdate" :form-config="updateDescription.config"
             ref="descriptionUpdatePop"></PopupForm>
  <PopupForm :show-popup="updatePersonInfo.show" pop-title="编辑个人信息" :confirm-action="doUpdatePersonInfo"
             @close-popup="closePersonInfoUpdate" :form-config="updatePersonInfo.config"
             ref="updatePersonInfoRef"></PopupForm>
  <PopupForm :show-popup="updateCompanyInfo.show" pop-title="编辑公司信息" :confirm-action="doUpdateCompanyInfo"
             @close-popup="closeCompanyInfoUpdate" :form-config="updateCompanyInfo.config" :label-width="'120px'"
             ref="updateCompanyInfoRef"></PopupForm>
  <PopupForm :show-popup="updatePostInfo.show" pop-title="编辑邮寄信息" :confirm-action="doUpdatePostInfo"
             @close-popup="closePostInfoUpdate" :form-config="updatePostInfo.config"
             ref="updatePostInfoRef"></PopupForm>
  <SupplierSelect ref="supplierSelectRef" :confirmAction="doAssignSupplier"/>
  <OrderRefund ref="orderRefundRef" />
  <ProductSelect ref="productSelectRef" />
</template>

<script>

import DateFormat from "@/components/util/DateFormat";
import Storage from "@/components/util/Storage";
import {ref} from "vue";
import OssBaseOrderApi from "@/api/OssBaseOrderApi";
import OrderLogApi from "@/api/OrderLogApi";
import OssOrderTrainingApi from "@/api/OssOrderTrainingApi";
import SupplierSelect from "@/components/Supplier/SupplierSelect";
import {ElMessage as Message, ElMessageBox} from 'element-plus'
import WxMpUserApi from "@/api/WxMpUserApi";
import VipDetailApi from "@/api/VipDetailApi";
import WxPayApi from "@/api/WxPayApi";
import OrderRefund from "@/components/SignMng/OrderRefund";
import ProductSelect from "@/components/product/ProductSelect";

export default {
  name: "SignMngDetail",
  mixins: [DateFormat, Storage, OssBaseOrderApi, OrderLogApi, OssOrderTrainingApi,WxMpUserApi,VipDetailApi,WxPayApi],
  components: {SupplierSelect,OrderRefund,ProductSelect},
  setup() {
    const descriptionUpdatePop = ref(null)
    const detailTrainClassSelectRef = ref(null)
    const updatePersonInfoRef = ref(null)
    const updateCompanyInfoRef = ref(null)
    const updatePostInfoRef = ref(null)
    const supplierSelectRef = ref(null)
    const orderRefundRef = ref(null)
    const productSelectRef = ref(null)
    return {
      descriptionUpdatePop, detailTrainClassSelectRef, updatePersonInfoRef, updateCompanyInfoRef
      , updatePostInfoRef, supplierSelectRef, orderRefundRef, productSelectRef
    }
  },
  emits: ["refreshList"],
  data() {
    return {
      show: false,
      orderNo: "",
      order: {},
      person: {},
      company: {},
      post: {},
      orderLog: [],
      bindUser:[],
      loading: true,
      updateDescription: {
        show: ref(false),
        id: -1,
        config: [
          {
            type: 'area',
            title: '备注',
            placeHolder: '(非必填)',
            bindField: 'description',
            rowCount: 9,
            maxLength:1000,
            rules: [{
              required: false,
              message: '长度小于1000字',
              trigger: 'blur',
              pattern: /^.{0,1000}$/
            }]
          }
        ]
      },
      updatePersonInfo: {
        show: ref(false),
        config: [
          {
            type: 'input',
            title: '姓名',
            bindField: 'trueName',
            rules: [{required: true, message: '请输入姓名', trigger: 'blur'}]
          }, {
            type: 'input',
            title: '联系电话',
            bindField: 'phone',
            rules: [{required: true, message: '请输入联系', trigger: 'blur'}]
          }, {
            type: 'input',
            title: '身份证号',
            bindField: 'idCardNo',
            rules: [{required: true, message: '请输入身份证号', trigger: 'blur'}]
          }
        ]
      },
      updateCompanyInfo: {
        show: ref(false),
        config: [
          {
            type: 'input',
            title: '单位名称',
            bindField: 'company',
            rules: [{required: true, message: '请输入单位名称', trigger: 'blur'}]
          }, {
            type: 'input',
            title: '纳税人识别号',
            bindField: 'taxNo',
            rules: [{required: true, message: '请输入纳税人识别号', trigger: 'blur'}]
          }
        ]
      },
      updatePostInfo: {
        show: ref(false),
        config: [
          {
            type: 'switch',
            title: '是否自提',
            bindField: 'selfPick',
            trueText: '是',
            falseText: '否',
            rules: [{required: true, message: '请选择是否自提', trigger: 'blur'}]
          }, {
            type: 'input',
            title: '收件人',
            bindField: 'contactsName',
            placeHolder: '请输入收件人姓名(非必填)',
            rules: [{required: false, message: '请输入收件人姓名', trigger: 'blur'}]
          }, {
            type: 'input',
            title: '收件人电话',
            bindField: 'contactsPhone',
            placeHolder: '请输入收件人电话(非必填)',
            rules: [{required: false, message: '请输入收件人电话', trigger: 'blur'}]
          }, {
            type: 'input',
            title: '收件地址',
            bindField: 'address',
            placeHolder: '请输入收件地址(非必填)',
            rules: [{required: false, message: '请输入收件地址', trigger: 'blur'}]
          }, {
            type: 'input',
            title: '详细地址',
            bindField: 'roomNo',
            placeHolder: '请输入详细地址(非必填)',
            rules: [{required: false, message: '请输入详细地址', trigger: 'blur'}]
          }
        ]
      },
      idCardFrontTimeStamp: '',
      idCardBackTimeStamp: '',
      idPhotoTimeStamp: ''
    }
  },
  methods: {
    getDetail() {
      this.apiGetOrderDetail(this.$data.orderNo).then((response) => {
        let {order, person, company, post} = response.data
        this.$data.order = order
        this.$data.person = person
        this.$data.company = company
        this.$data.post = post
        this.$data.loading = false
        //查看所有手机
        const phones = []

        if(order.wxBindPhone){
          phones.push(order.wxBindPhone)
        }

        if(person.phone){
          let isContain = false
          for(let i of phones){
            if(i===person.phone){
              isContain = true
            }
          }
          if(!isContain){
            phones.push(person.phone)
          }
        }
        if(post.contactsPhone){
          let isContain = false
          for(let i of phones){
            if(i===post.contactsPhone){
              isContain = true
            }
          }
          if(!isContain){
            phones.push(post.contactsPhone)
          }
        }
        this.getWxMpBindUser(phones)
      })
      this.apiOrderLog(this.$data.orderNo).then((response) => {
        this.$data.orderLog = response.data ? response.data : []
      })
    },
    openDetail(orderNo) {
      this.$data.orderNo = orderNo
      this.getDetail()
      this.$data.show = true
    },
    closeDetail() {
      this.$data.loading = true
      this.$data.order = {}
      this.$data.person = {}
      this.$data.company = {}
      this.$data.post = {}
      this.$data.orderLog = []
    },
    /* 备注 */
    doUpdateOrderDescription(data) {
      this.apiUpdateComment(this.$data.orderNo, data.description).then(() => {
        this.$data.order.description = data.description
        this.closeDescriptionUpdate()
        this.$emit("refreshList")
      })
    },
    openUpdateDescription() {
      this.$data.updateDescription.show = true
      this.descriptionUpdatePop.initialData({description: this.$data.order.description})
    },
    closeDescriptionUpdate() {
      this.$data.updateDescription.show = false
    },
    cardFrontUpdateSuccess() {
      this.$data.idCardFrontTimeStamp = new Date().getTime()
    },
    cardBackUpdateSuccess() {
      this.$data.idCardBackTimeStamp = new Date().getTime()
    },
    idPhotoUpdateSuccess(){
      this.$data.idPhotoTimeStamp = new Date().getTime()
    },
    /* 更新个人信息 */
    doUpdatePersonInfo(params) {
      this.apiUpdatePersonInfo(this.$data.orderNo, params).then(() => {
        this.$data.person = params
        this.closePersonInfoUpdate()
        this.$emit("refreshList")
      })
    },
    openPersonInfoUpdate() {
      this.$data.updatePersonInfo.show = true
      this.updatePersonInfoRef.initialData(this.$data.person)
    },
    closePersonInfoUpdate() {
      this.$data.updatePersonInfo.show = false
    },
    /* 更新单位信息 */
    doUpdateCompanyInfo(params) {
      this.apiUpdateCompanyInfo(this.$data.orderNo, params).then(() => {
        this.$data.company = params
        this.closeCompanyInfoUpdate()
        this.$emit("refreshList")
      })
    },
    openCompanyInfoUpdate() {
      this.$data.updateCompanyInfo.show = true
      this.updateCompanyInfoRef.initialData(this.$data.company)
    },
    closeCompanyInfoUpdate() {
      this.$data.updateCompanyInfo.show = false
    },
    /* 更新邮寄信息 */
    doUpdatePostInfo(params) {
      this.apiUpdatePostInfo(this.$data.orderNo, params).then(() => {
        this.$data.post = params
        this.closePostInfoUpdate()
        this.$emit("refreshList")
      })
    },
    openPostInfoUpdate() {
      this.$data.updatePostInfo.show = true
      this.updatePostInfoRef.initialData(this.$data.post)
    },
    closePostInfoUpdate() {
      this.$data.updatePostInfo.show = false
    },
    /* 分派订单 */
    doAssignSupplier(ids, supplierId) {
      this.$data.loading = true
      this.apiAssignSupplier(ids, supplierId).then(() => {
        this.getDetail()
        this.$emit("refreshList")
      }, (error) => {
        Message.error(error.message)
        this.$data.loading = false
      })
    },
    openSupplierChoose() {
      this.supplierSelectRef.setVisible(true)
      this.supplierSelectRef.setCandidateIds(this.$data.orderNo)
    },
    beforePickUpdate() {
      return ElMessageBox.confirm(
          '上传后对原图片进行覆盖，覆盖后不可恢复，是否继续上传?',
          '注意',
          {
            confirmButtonText: '上传',
            cancelButtonText: '取消',
            type: 'warning',
          })
          .then(() => { return true })
          .catch(() => { return false })
    },
    /* 查看绑定用户 */
    getWxMpBindUser(phones){
      this.$data.bindUser = []
      for(let phone of phones){
        this.apiListBindUsers(phone).then((response)=>{
          this.$data.bindUser.push(
              {
                phone: phone,
                data: response.data
              }
          )
        })
      }
    },
    /* 开通模拟考试 */
    doAuthExam(userId){
      this.apiAddVipDetail(userId,this.$data.order.orderNo,this.$data.order.productNo,30)
          .then(()=>{
            Message.success("授权成功")
          },(error)=>{
            Message.error(error.message)
          })
    },
    doUnAuthExam(){
      this.apiCloseVipDetail(this.$data.order.orderNo)
          .then(()=>{
            Message.success("删除授权成功")
          },(error)=>{
            Message.error(error.message)
          });
    },
    /* 订单退款 */
    openRefundPopup(){
      this.orderRefundRef.openDetail(this.$data.order.orderNo)
    },
    /* 商品更换 */
    openProductSelect() {
      const { areaCode,seriesNo,industryNo,categoryNo } = this.$data.order
      this.productSelectRef.openPopup(areaCode,seriesNo,industryNo,categoryNo)
    },
    /* 订单冻结 */
    freezeOrder(){
      this.apiFreezeOrder(this.$data.orderNo).then(()=>{
        this.getDetail()
        this.$emit("refreshList")
      })
    },
    unfreezeOrder(){
      this.apiUnfreezeOrder(this.$data.orderNo).then(()=>{
        this.getDetail()
        this.$emit("refreshList")
      })
    },
    /* 重置用户信息 */
    doResetUserInfo(){
      this.apiResetUserInfo(this.$data.orderNo).then(()=>{
        this.getDetail()
        this.$data.idCardFrontTimeStamp = new Date().getTime()
        this.$data.idCardBackTimeStamp = new Date().getTime()
        this.$data.idPhotoTimeStamp = new Date().getTime()
      })
    }
  },
  mounted() {

  },
  computed: {
    postStatus() {
      if (this.$data.post.selfPick !== null) {
        return this.$data.post.selfPick === true ? "是" : "否"
      } else {
        return "未知"
      }
    },
    dealTime() {
      return this.dateFormat(this.$data.order.dealTime)
    },
    idCardFront() {
      return "/api/shopping/oss/order/" + this.$data.orderNo + "/pic/ID_CARD_FRONT?t=" + this.$data.idCardFrontTimeStamp
    },
    idCardBack() {
      return "/api/shopping/oss/order/" + this.$data.orderNo + "/pic/ID_CARD_BACK?t=" + this.$data.idCardBackTimeStamp
    },
    idPhoto() {
      return "/api/shopping/oss/order/" + this.$data.orderNo + "/pic/ID_PHOTO?t="+this.$data.idPhotoTimeStamp
    },
    updateCardFrontPath() {
      return "/api/shopping/oss/order/" + this.$data.orderNo + "/pic/ID_CARD_FRONT"
    },
    updateCardBackPath() {
      return "/api/shopping/oss/order/" + this.$data.orderNo + "/pic/ID_CARD_BACK"
    },
    updateIDPhotoPath() {
      return "/api/shopping/oss/order/" + this.$data.orderNo + "/pic/ID_PHOTO"
    },
    orderLogRender() {
      return this.$data.orderLog.map((row) => {
        let __operatorTypeName
        switch (row.operatorType) {
          case "EMPLOYEE":
            __operatorTypeName = "";
            break;
          case "SUPPLIER":
            __operatorTypeName = "供应商";
            break;
          case "CUSTOMER":
            __operatorTypeName = "学员";
            break;
          case "SYSTEM":
            __operatorTypeName = "系统";
            break;
          default:
            __operatorTypeName = ""
        }
        row.__operatorType = __operatorTypeName
        row.__createTime = this.dateFormat(row.createTime)
        return row
      })
    }
  }
}
</script>

<style scoped>
.id-card-img {
  display: inline-block;
  margin-right: 20px;
  cursor: pointer;
}

.id-card-img .el-image {
  width: 250px;
  height: 170px;
}
.id-card-img.id-photo .el-image {
  height: 175px;
  width: 125px;
}

.id-card-img .demonstration {
  color: var(--el-text-color-secondary);
  font-size: 14px;
  margin-bottom: 20px;
}

.id-card-img .image-slot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: var(--el-fill-color-light);
  color: var(--el-text-color-secondary);
  font-size: 22px;
}

.id-card-img .image-slot .el-icon {
  font-size: 22px;
}

.id-card-img .id-card-img-des {
  font-size: 18px;
}

.id-card-img .id-card-name {
  text-align: center;
  color: #919399;
  margin-bottom: 5px;
}

.id-card-img .el-upload__tip {
  color: #d6d6d6;
}
.exam-auth{
  width: 70px;
  text-align: center;
  display: inline-block;
  margin-right: 18px;
}
.exam-auth img{
  margin: 0 auto;
}
.exam-auth .exam-auth-account{
  margin:0 auto;
  padding:5px;
  color: #7F7F7F;
  overflow: hidden;
  word-break: keep-all;
}
.exam-auth button{
  margin-left: 0 !important;
}

</style>