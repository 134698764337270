<template>
  <el-dialog v-model="showPopupWindow" title="退款" custom-class="akz-popup"
             @close="closePopup" :destroy-on-close="true">
    <el-form ref="ruleFormRef" :model="formData" :rules="validateRules"  label-position="center" :label-width="labelWidth"
             class="akz-popup-form" status-icon>
      <el-form-item label="订单金额" prop="orderAmount" >
        <el-input v-model="formData.orderAmount" disabled></el-input>
      </el-form-item>
      <el-form-item label="退款金额" prop="refundAmount" >
        <el-input v-model="formData.refundAmount" placeholder="请输入退款金额"></el-input>
      </el-form-item>
      <el-form-item label="手机验证码" prop="validateCode" >
        <el-input v-model="formData.validateCode" :placeholder="validatePhonePlaceHolder"></el-input>
        <el-button :type="sendSmsCode?'info':'primary'" @click="sendValidateSmsCode" class="akz-btn" style="margin-top:5px;" :disabled="sendSmsCode">{{ sendMsgBtnText }}</el-button>
      </el-form-item>
      <el-form-item label="退款说明" prop="comment" >
        <el-input v-model="formData.comment"
                  show-word-limit="true" maxlength="80"
                  type="textarea" placeholder="请输入退款说明,退款说明会显示在客户的退款详情里（非必填）"/>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="closePopup" link class="akz-btn" style="color: #A4A4A4;font-weight: 400">取消</el-button>
        <el-button type="primary" @click="doConfirm" class="akz-btn" :disabled="enableConfirm">确认退款</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import {ref} from "vue";
import WxPayApi from "@/api/WxPayApi";
import {ElMessage as Message} from "element-plus"
import OssBaseOrderApi from "@/api/OssBaseOrderApi";

export default {
  name: "OrderRefund",
  mixins:[ WxPayApi,OssBaseOrderApi ],
  setup() {
    const ruleFormRef = ref(null)
    return {
      ruleFormRef
    }
  },
  data(){
    return {
      showPopupWindow: false,
      orderNo:"",
      formData:{
        orderAmount:"0",
        refundAmount:"0",
        validateCode:"",
        comment:"",
        validatePhone:""
      },
      validateRules:{
        refundAmount:[
          { required: true, message: '请输入退款金额', trigger: 'blur' },
          { validator: this._validateRefundAmount, trigger: 'blur' }
        ],
        comment:[
          { max: 80, message: '不能超过80个字', trigger: 'blur' }
        ]
      },
      labelWidth: 100,
      enableConfirm: true,
      sendMsgBtnText: "发送验证码",
      sendSmsCode: false
    }
  },
  methods:{
    _validateRefundAmount (rule, value, callback){
      if (value === '') {
        callback(new Error('请输入正确的退款金额'))
      } else {
        const numRe = new RegExp('^[-\\+]?([0-9]+\\.?)?[0-9]+$')
        if (!numRe.test(value)) {
          callback(new Error('请输入正确的退款金额'))
        }else{
          const _value = parseFloat(value)
          if(_value < 0.01){
            callback(new Error('退款金额需大于0.01'))
          }else if(_value > this.$data.formData.orderAmount){
            callback(new Error('退款金额不能大于订单金额  '+ this.$data.formData.orderAmount))
          }else{
            callback()
          }
        }
      }
    },
    openDetail(orderNo) {
      this.$data.orderNo = orderNo
      this.$data.showPopupWindow = true
      this.getDetail(orderNo)
    },
    closePopup: function () {
      this.$data.showPopupWindow = false
      this.$emit("close-popup")
    },
    doConfirm: function () {
      if(this.$data.formData.validateCode.length < 1){
        Message.error("请输入手机验证码")
        return
      }

      this.ruleFormRef.validate((valid) => {
        if (valid) {
          this.doApplyRefund()
        } else {
          return
        }
      })
    },
    doSendValidateCode(orderNo,refundAmount){
      this.apiSendRefundMsg(orderNo,refundAmount * 100).then((response)=>{
        Message.success(response.data)
      },(error)=>{
        Message.error(error.message)
      })
    },
    doApplyRefund(){
      this.apiRefund(this.$data.orderNo,parseFloat(this.$data.formData.refundAmount)*100,
          this.$data.formData.validateCode,this.$data.formData.comment).then(()=>{
          this.closePopup()
      },(error)=>{
        Message.error(error.message)
      })
    },
    getDetail(orderNo){
      this.apiGetOrderDetailSimple(orderNo).then((response)=>{
        const { orderStatus,orderFee,assignStatus,validatePhone } = response.data
        const amount = (parseFloat(orderFee)/100).toFixed(2);
        this.$data.formData.orderAmount = amount;
        this.$data.formData.refundAmount = amount
        this.$data.formData.validatePhone = validatePhone;

        if("PAY" === orderStatus || "PART_REFUND" === orderStatus){
          if("ASSIGNED" === assignStatus || "CONFIRMED" === assignStatus){
            Message.error("订单已报送供应商，需退回后退款")
            return;
          }else {
            this.$data.enableConfirm = false
          }
        }else{
          Message.error("订单状态需为[已支付]或[部分退款]")
          this.$data.enableConfirm = false
          return
        }
      })
    },
    sendValidateSmsCode(){
      const _this = this
      if(this.$data.sendSmsCode){
        return
      }
      this.$data.sendSmsCode = true
      this.ruleFormRef.validate((valid) => {
        if (valid) {
          this.doSendValidateCode(this.$data.orderNo,parseFloat(this.$data.formData.refundAmount)).then(()=>{
            //倒数
            let countDown = 60
            const sendSmsInterval = setInterval(function (){
              _this.$data.sendMsgBtnText = countDown + "秒后再次发送"
              countDown--
              if(countDown <= 0){
                _this.$data.sendSmsCode = false
                _this.$data.sendMsgBtnText = "发送验证码"
                clearInterval(sendSmsInterval)
              }
            },1000)
          },(error)=>{
            this.$data.sendSmsCode = false
            Message.error(error.message)
          })
        } else {
          Message.error("请输入正确退款内容后发送")
          this.$data.sendSmsCode = false
          return
        }
      })
    }
  },computed:{
    validatePhonePlaceHolder:function (){
      return "请输入发送给手机 "+this.$data.formData.validatePhone+" 的短信验证码"
    }
  }
}
</script>

<style scoped>

</style>