import request from "@/components/util/http";


export default {
    methods:{
        doLogin(userName,pwd,validateCode,validateTag){
            return request({
                url: '/api/oss-user/login',
                method: 'post',
                data: {
                    userName: userName,
                    pwd: pwd,
                    validateTag : validateTag,
                    validateCode : validateCode
                }
            })
        },
        checkIsLogin(token){
            return request({
                url: '/api/oss-user/check?token='+token,
                method: 'get'
            })
        },
        doLogout(token){
            return request({
                url: '/api/oss-user/logout?token='+token,
                method: 'put'
            })
        }
    }
}