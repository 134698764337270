import {createApp} from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import store from "@/store";
import ConditionFilter from "@/components/frame/conditionFilters/ConditionFilter";
import PopupForm from "@/components/frame/popUp/PopupForm";
import router from "@/router/router";

//创建app
const app = createApp(App)
    .use(router)
    .use(ElementPlus,{ locale: zhCn })
    .use(store)
/**
 *  加载Element UI组件
 */
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.component("ConditionFilter",ConditionFilter)
app.component("PopupForm",PopupForm)

app.mount('#app')
