<template>
  <ConditionFilter :filterConfig="filterConfig" @doQuery="search" :show-date-ranger="true" :do-query-when-start="true"
                   :close-after-query="true" ref="conditionFilter"></ConditionFilter>
  <div v-loading="loading">
    <div class="table">
      <el-table
          :data="listData"
          style="width: 100%"
      >
        <el-table-column type="selection" width="50" align="center">
        </el-table-column>
        <el-table-column prop="orderNo" label="订单号" align="left" header-align="left" width="200">
        </el-table-column>
        <el-table-column prop="subjectType" label="报名科目" align="left" header-align="left" width="200px">
          <template #default="scope">
            {{ scope.row.productName }}
          </template>
        </el-table-column>
        <el-table-column prop="customerName" label="姓名" align="center" width="60">
        </el-table-column>
        <el-table-column prop="__orderStatus" label="订单状态" align="center" width="90">
        </el-table-column>
        <el-table-column prop="__description" label="备注" align="left" header-align="left" >
        </el-table-column>
        <el-table-column prop="__dealTime" label="报名时间" align="center" width="160">
        </el-table-column>
        <el-table-column label="操作" width="200" align="center" header-align="center">
          <template #default="scope">
            <el-button link class="akz-link" @click="edit(scope.row.orderNo)">详情</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-item">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="record.currentIndex"
          :page-sizes="[20, 50, 100]"
          :page-size="record.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="record.total"></el-pagination>
    </div>
  </div>
  <SignMngDetail ref="signMngDetailRef" @refresh-list="search" />
</template>

<script>
import {ref} from "vue";
import dateFormat from "@/components/util/DateFormat";
import OssBaseOrderApi from "@/api/OssBaseOrderApi";
import SignMngDetail from "@/components/SignMng/SignMngDetail";
import OssOrderTrainingApi from "@/api/OssOrderTrainingApi";

export default {
  name: "SignMng",
  components: { SignMngDetail },
  emits: ["goto-module"],
  mixins:[dateFormat,OssBaseOrderApi,OssOrderTrainingApi],
  setup() {
    const conditionFilter = ref(null)
    const signMngDetailRef = ref(null)
    return {
      conditionFilter,signMngDetailRef
    }
  },
  data() {
    return {
      loading:false,
      record: {
        list: [],
        currentIndex: 1,
        total: 100,
        pageSize: 20,
        selected: [],
        statistic:{
          startTime:'2000-01-01 00:00:00',
          endTime:'2000-01-01 00:00:00',
          orderCount: 0,
          preAmount: 0,
          amount: 0
        }
      },
      filterConfig: [
        {
          type: 'input',
          title: '订单号',
          placeHolder: '请输入...',
          bindField: 'orderNo',
          showInMain: true
        },{
          type: 'input',
          title: '产品编号',
          placeHolder: '请输入...',
          bindField: 'productNo'
        },{
          type: 'input',
          title: '产品名称',
          placeHolder: '请输入...',
          bindField: 'productName',
          showInMain: true
        },{
          type: 'input',
          title: '备注',
          placeHolder: '请输入...',
          bindField: 'description'
        }
      ],
      tabsConfig: [
        {id: 1, tabName: "未报送",value : "UN_ASSIGNED"},
        {id: 2, tabName: "已报送",value : "ASSIGNED"},
        {id: 3, tabName: "已确认",value : "CONFIRMED"},
        {id: 4, tabName: "退回中",value : "RETURNING"},
        {id: 5, tabName: "全部",value : ""}
      ]
    }
  },
  methods:{
    doQuery(filters) {
      //获取分页信息
      const _currentIndex = this.$data.record.currentIndex
      const _pageSize = this.$data.record.pageSize
      this.$data.loading = true

      filters["orderStatus"] = "REFUND"

      //获取页大小和页数
      this.apiListBaseOrder(filters, _pageSize, _currentIndex).then((response) => {
        this.$data.loading = false
        this.$data.record.total = response.data.total
        this.$data.record.list = response.data.data ? response.data.data : []
      })
    },
    search() {
      const filters = this.conditionFilter.getFilters()
      this.doQuery(filters)
    },
    handleSizeChange(pageSize) {
      this.record.pageSize = pageSize
      this.search()
    },
    handleCurrentChange(pageIndex) {
      this.record.currentIndex = pageIndex
      this.search()
    },
    handleSelectionChange(changed) {
      this.$data.record.selected = changed.map(x => x.orderNo)
    },
    edit(orderNo){
      this.signMngDetailRef.openDetail(orderNo)
    }
  },
  computed:{
    listData: function () {
      return this.$data.record.list.map((row) => {
        //处理备注
        if(row.description && row.description.length>70){
          row.__description = row.description.substring(0,70)+"..."
        }else {
          row.__description = row.description
        }
        //处理订单状态
        let orderStatusDes;
        switch (row.orderStatus){
          case "UN_PAY": orderStatusDes = "待支付";break;
          case "PAY": orderStatusDes = "已支付";break;
          case "CANCEL": orderStatusDes = "已取消";break;
          case "ERROR": orderStatusDes = "支付失败";break;
          case "REFUNDING": orderStatusDes = "退款中";break;
          case "REFUND": orderStatusDes = "已退款";break;
          default: orderStatusDes = "未知"
        }
        row.__orderStatus = orderStatusDes

        //处理时间显示
        row.__dealTime = this.dateFormat(row.dealTime)
        return row
      })
    }
  }
}
</script>

<style scoped>

</style>