<template>
  <el-dialog v-model="showPopupWindow" title="商品选择" custom-class="akz-popup product-select"
             @close="closePopup" :destroy-on-close="true">
    <div v-loading="isLoading">
      <el-row>
        <el-col :span="3">地区</el-col>
        <el-col :span="21"><span class="akz-tag">{{ areaName }}</span>
          <el-button type="primary" link class="akz-btn" @click="openAreaSelect">变更</el-button>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="3"><span>服务类型</span></el-col>
        <el-col :span="21">
        <span v-for="series in productSeries" :key="series.seriesNo" @click="onSeriesSelect(series.seriesNo)"
              class="select-item"
              style="margin-right: 5px;" :class="series.seriesNo===seriesNo?'akz-tag success':'akz-tag'">
          {{ series.seriesName }}
        </span>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="3"><span>行业</span></el-col>
        <el-col :span="21">
          <span v-for="industry in productIndustry" :key="industry.industryCode" @click="onIndustrySelect(industry.industryCode)"
                class="select-item"
                style="margin-right: 5px;" :class="industry.industryCode===industryCode?'akz-tag success':'akz-tag'">
          {{ industry.industryName }}
          </span>
          <span class="select-item" v-if="!productIndustry || productIndustry.length===0">无</span>
        </el-col>
      </el-row>
<!--      <el-row>
        <el-col :span="3"><span>商品名称</span></el-col>
        <el-col :span="21"><el-input v-model="search.fuzzySearch" placeholder="请输入商品名称关键字" /></el-col>
      </el-row>-->
      <el-row>
        <el-col :span="6">
          <el-tree :data="category" :props="categoryTreeProps" ref="categoryTreeRef"
                   node-key="categoryNo" :highlight-current="true" @node-click="onCategorySelect" />
        </el-col>
        <el-col :span="18">
          <div class="table">
            <el-table
                :data="record.list"
                style="width: 100%"
                height="300"
                @selection-change="handleSelectionChange">
              <el-table-column type="selection" width="50" align="center">
              </el-table-column>
              <el-table-column prop="customerName" label="姓名" align="center" width="60">
              </el-table-column>
              <el-table-column prop="__description" label="备注" align="left" header-align="left" >
              </el-table-column>
              <el-table-column prop="__dealTime" label="报名时间" align="center" width="160">
              </el-table-column>
            </el-table>
          </div>
          <div class="pagination-item">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="record.currentIndex"
                :page-sizes="[20, 50, 100]"
                :page-size="record.pageSize"
                layout="total, sizes, prev, pager, next"
                :total="record.total"></el-pagination>
          </div>
        </el-col>
      </el-row>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="closePopup" link class="akz-btn" style="color: #A4A4A4;font-weight: 400">取消</el-button>
        <el-button type="primary" @click="doConfirm" class="akz-btn" >确认</el-button>
      </span>
    </template>
  </el-dialog>
  <AreaSelect ref="areaSelectRef" @close-popup="onAreaSelect" />
</template>

<script>
import ProductIndustryApi from "@/api/ProductIndustryApi";
import AreaApi from "@/api/AreaApi";
import ProductSeriesApi from "@/api/ProductSeriesApi";
import ProductCategoryApi from "@/api/ProductCategoryApi";
import {ElMessage as Message} from "element-plus";
import AreaSelect from "@/components/product/AreaSelect";
import {ref} from "vue";

export default {
  name: "ProductSelect",
  emits:["close-popup"],
  mixins:[ ProductIndustryApi,AreaApi,ProductSeriesApi,ProductCategoryApi ],
  components:{ AreaSelect },
  setup(){
    const categoryTreeRef = ref(null)
    const areaSelectRef = ref(null)
    return { categoryTreeRef,areaSelectRef }
  },
  data(){
    return {
      search:{
        fuzzySearch:""
      },
      areaName:"",
      productSeries:[],
      productIndustry:[],
      category:[],
      showPopupWindow: false,
      enableConfirm: true,
      areaCode:'',
      seriesNo:'',
      industryCode:'',
      categoryNo:'',
      isLoading : false,
      categoryTreeProps: {
        children: 'sub',
        label: 'categoryName',
      },
      record: {
        list: [],
        currentIndex: 1,
        total: 100,
        pageSize: 20,
        selected: [],
      },
    }
  },
  methods:{
    openPopup(areaCode,seriesNo,industryNo,categoryNo) {
      this.$data.isLoading = true
      this.$data.showPopupWindow = true
      this.$data.areaCode = areaCode
      this.$data.seriesNo = seriesNo
      this.$data.industryCode = industryNo
      this.$data.categoryNo = categoryNo
      const loadArea = this.loadAreaName()
      const loadSeries = this.loadSeries()
      const loadProductIndustry = this.loadProductIndustry()
      const loadCategory = this.loadForCategory()

      Promise.all([loadArea,loadSeries,loadProductIndustry,loadCategory]).then((res)=>{
        this.$data.isLoading = false
        //industry
        this.$data.productIndustry = res[2].data
        //category
        this.$data.category = res[3].data
        this.handleCategoryDataChange()
      },()=>{
        Message.error("数据初始化出现错误")
      })
    },
    closePopup: function () {
      this.$data.showPopupWindow = false
      this.$data.category = []
      this.$emit("close-popup")
    },
    /* industry */
    loadProductIndustry(){
      return this.apiIndustryForArea(this.$data.areaCode)
    },
    onIndustrySelect(industryCode){
      this.$data.industryCode = industryCode
      this.loadCategory(this.$data.seriesNo,this.$data.industryCode)
    },
    loadAreaName(){
      if(this.$data.areaCode===""){
        return
      }
      this.apiAreaForCode(this.$data.areaCode).then((res)=>{
        this.$data.areaName = res.data.areaName
      })
    },
    /* series */
    loadSeries(){
      return this.apiSeries().then((res)=>{
        if(res.data){
          res.data.forEach((row)=>{
            if(row.seriesNo === this.$data.seriesNo){
              this.$data.seriesNo = row.seriesNo
            }
          })
        }
        this.$data.productSeries = res.data
      })
    },
    onSeriesSelect(seriesNo){
      this.$data.seriesNo = seriesNo
      this.loadCategory(this.$data.seriesNo,this.$data.industryCode)
    },
    /* category */
    loadCategory(seriesNo,industryCode){
      if(seriesNo === null || industryCode === null){
        this.$data.category = []
      }else{
        this.apiLoadCategories(seriesNo,industryCode).then((res)=>{
          this.$data.category = res.data
        })
      }
    },
    loadForCategory(){
      return this.apiLoadForFamily(this.$data.categoryNo)
    },
    onCategorySelect(selected){
      console.log(selected)
    },
    handleCategoryDataChange(){
      this.$nextTick(() => {
        this.categoryTreeRef.setCurrentKey(this.$data.categoryNo)
        //获取当前节点
        const currentNode = this.categoryTreeRef.getCurrentNode()
        if(currentNode){
          this.$data.industryCode = currentNode.industryCode
        }
      })
    },
    /* 确认 */
    doConfirm(){

    },
    /* 打开地区选择 */
    openAreaSelect(){
      this.areaSelectRef.openPopup(this.$data.areaCode)
    },
    onAreaSelect(data){
      if(data.code === this.$data.areaCode){
        return
      }
      this.$data.areaCode = data.code
      this.$data.areaName = data.name

      this.loadProductIndustry().then((res)=>{
        //industry
        this.$data.productIndustry = res.data===null?[]:res.data

        if(this.$data.productIndustry && this.$data.productIndustry.length > 0){
          this.onIndustrySelect(this.$data.productIndustry[0].industryCode)
        }else{
          this.onIndustrySelect(null)
        }
      })
    }
  },
  mounted() {
  }
}
</script>

<style scoped>
  .select-item{
    cursor: pointer;
  }
  .pagination-item{
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
</style>