import Cookies from 'js-cookie'

const CookiesUtil = {
    methods: {
        setCookie(key,value, cookieExpires){
            Cookies.set(key, value, { expires: cookieExpires || 1 })
        },
        getCookie(key){
            return Cookies.get(key)
        },
        delCookie(key){
            Cookies.remove(key)
        }
    }
}
export default CookiesUtil