import request from "@/components/util/http";


export default {
    methods:{
        apiListForSelect(){
            return request({
                url : '/api/supplier/oss/supplier/select',
                method: 'get'
            })
        }
    }
}