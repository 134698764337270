import request from "@/components/util/http";


export default {
    methods:{
        apiGetOperatorInfo(){
            return request({
                url: '/api/auth/oss/employee/operator',
                method: 'get'
            })
        }
    }
}